body{
  overflow-y: scroll;
}

.lhs-inner-wrapper>div {
  transition: ease-in-out .5s;
}

.close .lhs-items {
  left: -280px !important;
}

.close .lhs-inner-wrapper>div {
  overflow: hidden;
}

.close .lhs-content,
.close .lhs-inner-wrapper,
.close .lhs-inner-wrapper>div,
.close .lhs-wrapper {
  width: 60px !important;
}
.postlogin-wrapper:has(.lhs-wrapper.fixed){
  justify-content: end;
}

.close .rhs-wrapper,
.close .header.fixed {
  width: calc(100% - 60px);
}

.postlogin-wrapper:has(.lhs-wrapper.fixed) .header.fixed,
.postlogin-wrapper:has(.lhs-wrapper.fixed) .rhs-wrapper{
  width: calc(100% - 60px);
}

/* .gradient .primary-button{
  background: linear-gradient(45deg,#5a5278,#e8769f) !important;
} */
